import { IAddress } from 'interfaces/address'
import { IAccount } from 'interfaces/account'
import { ICompany } from 'interfaces/company'
import { IUser } from 'interfaces/user'

const TOKEN_KEY = 'token'
const USER_KEY = 'user'
const COMPANY_KEY = 'company'
const ACCOUNT_KEY = 'account'
const ADDRESS_KEY = 'address'

export function setToken(token: string) {
  sessionStorage.setItem(TOKEN_KEY, token)
}

export function setUser(user: IUser) {
  sessionStorage.setItem(USER_KEY, JSON.stringify(user))
}

export function setAccount(account: IAccount) {
  sessionStorage.setItem(ACCOUNT_KEY, JSON.stringify(account))
}

export function setCompany(company: ICompany) {
  sessionStorage.setItem(COMPANY_KEY, JSON.stringify(company))
}

export function setAddress(address: IAddress) {
  sessionStorage.setItem(ADDRESS_KEY, JSON.stringify(address))
}

export function getToken(): string | null {
  return sessionStorage.getItem(TOKEN_KEY)
}

export function getUser(): IUser | null {
  const data = sessionStorage.getItem(USER_KEY)
  if (data !== null) {
    return JSON.parse(data) as IUser
  }
  return null
}

export function getAccount(): IAccount | null {
  const data = sessionStorage.getItem(ACCOUNT_KEY)
  if (data !== null) {
    return JSON.parse(data) as IAccount
  }
  return null
}

export function getCompany(): ICompany | null {
  const data = sessionStorage.getItem(COMPANY_KEY)
  if (data !== null) {
    return JSON.parse(data) as ICompany
  }
  return null
}

export function getAddress(): IAddress | null {
  const data = sessionStorage.getItem(ADDRESS_KEY)
  if (data !== null) {
    return JSON.parse(data) as IAddress
  }
  return null
}

export function clearSession() {
  sessionStorage.clear()
}
