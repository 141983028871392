import { Suspense, lazy } from 'react'
import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom'
import LogionLayout from 'layout/LoginLayout'
import ProtectedLayout from 'layout/ProtectedLayout'
import PublicLayout from 'layout/PublicLayout'
import PublicProtectedLayout from 'layout/PublicProtectedLayout'
import { FormEdit } from 'enum/FormEdit'
import { QrCodeType } from 'enum/QrCodeType'
import Home from 'routes/Home'
import Login from 'routes/Login'

const Dashboard = lazy(() => import('routes/Dashboard'))
const User = lazy(() => import('routes/User'))
const Company = lazy(() => import('routes/Company'))
const Category = lazy(() => import('routes/Category'))
const Project = lazy(() => import('routes/Project'))
const Position = lazy(() => import('routes/Position'))
const Register = lazy(() => import('routes/Register'))
const Feature = lazy(() => import('routes/Feature'))
const Pricing = lazy(() => import('routes/Pricing'))
const QrViewer = lazy(() => import('routes/QrViewer'))
const Print = lazy(() => import('routes/Print'))
const ShowPdf = lazy(() => import('routes/ShowPdf')) // for testing

function RouteProjectEdit() {
  const params = useParams()
  if (params.id) {
    return <Project id={params.id} variant={FormEdit.Edit} />
  }
  return <Project variant={FormEdit.New} /> // maybe error page?
}
function RouteProjectView() {
  const params = useParams()
  if (params.id) {
    return <Project id={params.id} variant={FormEdit.View} />
  }
  return <Project variant={FormEdit.New} /> // maybe error page?
}
function RouteProjectPrint() {
  const params = useParams()
  if (params.id) {
    return <Print id={params.id} />
  }
  return <Project variant={FormEdit.New} /> // maybe error page?
}

function RoutePositionEdit() {
  const params = useParams()
  if (params.id) {
    return <Position id={params.id} projectId={params.projectId} variant={FormEdit.Edit} />
  }
  return <Position variant={FormEdit.New} /> // maybe error page?
}
function RoutePositionView() {
  const params = useParams()
  if (params.id) {
    return <Position id={params.id} projectId={params.projectId} variant={FormEdit.View} />
  }
  return <Position variant={FormEdit.New} /> // maybe error page?
}
function RoutePositioNew() {
  const params = useParams()
  if (params.id) {
    return <Position projectId={params.id} variant={FormEdit.New} />
  }
  return <Position variant={FormEdit.New} /> // maybe error page?
}

function RouteQrProject() {
  const params = useParams()
  if (params.id) {
    return <QrViewer projectId={params.id} type={QrCodeType.Project} />
  }
  return <QrViewer projectId={''} type={QrCodeType.Unknown} />
}

function RouteQrPosition() {
  const params = useParams()
  if (params.id && params.pId) {
    return <QrViewer projectId={params.pId} positionId={params.id} type={QrCodeType.Position} />
  }
  return <QrViewer projectId={''} type={QrCodeType.Unknown} />
}

function RouteCategoryEdit() {
  const params = useParams()
  if (params.id) {
    return <Category id={params.id} variant={FormEdit.Edit} />
  }
  return <Category variant={FormEdit.New} /> // maybe error page?
}
function RouteCategoryView() {
  const params = useParams()
  if (params.id) {
    return <Category id={params.id} variant={FormEdit.View} />
  }
  return <Category variant={FormEdit.New} /> // maybe error page?
}

function App() {
  return (
    <>
      <Header />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route element={<LogionLayout />}>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
            </Route>
            <Route element={<ProtectedLayout />}>
              <Route path='/dashbord' element={<Dashboard />} />
              <Route path='/project/create' element={<Project variant={FormEdit.New} />} />
              <Route path='/project/view/:id' element={<RouteProjectView />} />
              <Route path='/project/edit/:id' element={<RouteProjectEdit />} />
              <Route path='/project/print/:id' element={<RouteProjectPrint />} />
              <Route path='/project' element={<Project variant={undefined} />} />
              <Route path='/position/create/:id' element={<RoutePositioNew />} />
              <Route path='/position/view/:projectId/:id' element={<RoutePositionView />} />
              <Route path='/position/edit/:projectId/:id' element={<RoutePositionEdit />} />
              <Route path='/company' element={<Company />} />
              <Route path='/category/create' element={<Category variant={FormEdit.New} />} />
              <Route path='/category/view/:id' element={<RouteCategoryView />} />
              <Route path='/category/edit/:id' element={<RouteCategoryEdit />} />
              <Route path='/category' element={<Category variant={undefined} />} />
            </Route>
            <Route element={<PublicProtectedLayout />}>
              <Route path='/QR/FP/PJ/:id' element={<RouteQrProject />} />
              <Route path='/QR/FP/PO/:pId/:id' element={<RouteQrPosition />} />
              <Route path='/pdf' element={<ShowPdf />} />{/* for testing */}
            </Route>
            <Route element={<PublicLayout />}>
              <Route path='/' element={<Home />} />
              <Route path='/feature' element={<Feature />} />
              <Route path='/pricing' element={<Pricing />} />
            </Route>
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer />
      <Footer />
    </>
  )
}

export default App
