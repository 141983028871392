import React from 'react'
import { Outlet } from 'react-router'
import { isUserLoggedIn } from 'services/authService'
import ProtectedLayout from './ProtectedLayout'
import PublicLayout from './PublicLayout'

export default class PublicProtectedLayout extends React.Component {
  render() {
    const Wrapper = isUserLoggedIn() ? ProtectedLayout : PublicLayout
    return (
      <Wrapper>
        <Outlet />
      </Wrapper>
    )
  }
}
