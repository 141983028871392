import React from 'react'
import { Container } from 'react-bootstrap'
import { Navigate, Outlet } from 'react-router'
import { isUserLoggedIn } from 'services/authService'
import MenuBar from '../components/HeaderNavbar'
import { NavbarVariant } from 'enum/NavbarVariant'
import Sidebar from '../components/SideNavbar'
import './Layout.scss'

export default class ProtectedLayout extends React.Component {
  render() {
    if (!isUserLoggedIn()) {
      return <Navigate to='/login' />
    }
    return (
      <div className='layoutContainer'>
        <MenuBar variant={NavbarVariant.Main} />
        <Container className='containerWithSidebar'>
          <Sidebar />
          <Outlet />
        </Container>
      </div>
    )
  }
}
