import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Navbar, Nav } from 'react-bootstrap'
import { logoutUser } from 'services/userService'
import { isUserLoggedIn } from 'services/authService'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router'
import { PlusSquare as RegistrationIcon, BoxArrowInRight as LoginIcon, BoxArrowRight as LogoutIcon } from 'react-bootstrap-icons'
import { NavbarVariant } from 'enum/NavbarVariant'
import { getUser } from 'services/sessionService'
import './HeaderNavbar.scss'
import { Link } from 'react-router-dom'

type State = {
  redirect: string | undefined
}

type Props = {
  variant: NavbarVariant
}

export default class HeaderNavbar extends React.Component<Props, State> {
  state: State = {
    redirect: undefined,
  }
  static propTypes = {
    variant: PropTypes.oneOf(Object.values(NavbarVariant) as NavbarVariant[]),
  }

  logOut = () => {
    console.log('logout')
    toast
      .promise(logoutUser(), {
        pending: 'Logout ⌛',
        success: 'Logout erfolgreich',
        error: 'Logout error!',
      })
      .then(() => {
        this.setState({ redirect: '/' })
      })
      .catch((e: Error) => {
        console.log('Logout error', e.message)
      })
  }

  logIn = () => {
    console.log('login')
    this.setState({ redirect: '/login' })
  }

  register = () => {
    console.log('register')
    this.setState({ redirect: '/register' })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    const userisLoggedIn = isUserLoggedIn()
    const { variant } = this.props

    return (
      <Navbar>
        <Container>
          {variant === NavbarVariant.Home && (
            <Nav.Link as={Link} to='/'>
              <h4 className='navbar-link'>Home</h4>
            </Nav.Link>
          )}
          {variant === NavbarVariant.Home && (
            <Nav.Link as={Link} to='/feature'>
              <h4 className='navbar-link'>Feature</h4>
            </Nav.Link>
          )}
          {variant === NavbarVariant.Home && (
            <Nav.Link as={Link} to='/pricing'>
              <h4 className='navbar-link'>Preise</h4>
            </Nav.Link>
          )}
          {variant === NavbarVariant.Home && <Navbar.Toggle />}
          <Navbar.Collapse className='justify-content-end'>
            {!userisLoggedIn && (
              <Navbar.Text>
                <Button variant='outline-primary' onClick={this.register}>
                  <RegistrationIcon />
                </Button>
              </Navbar.Text>
            )}
            {!userisLoggedIn && (
              <Navbar.Text>
                <Button variant='outline-primary' onClick={this.logIn}>
                  <LoginIcon />
                </Button>
              </Navbar.Text>
            )}
            {userisLoggedIn && (
              <>
                <Navbar.Text>
                  <span>Eingeloggt als: {getUser()?.firstName}</span>
                </Navbar.Text>
                <Navbar.Text>
                  <Button variant='outline-primary' onClick={this.logOut}>
                    <LogoutIcon />
                  </Button>
                </Navbar.Text>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}
