import React from 'react'
import { Stack } from 'react-bootstrap'
import { Outlet } from 'react-router'
import HeaderNavbar from '../components/HeaderNavbar'
import { NavbarVariant } from 'enum/NavbarVariant'
import './Layout.scss'

export default class PublicLayout extends React.Component {
  render() {
    return (
      <Stack className='layoutContainer'>
        <HeaderNavbar variant={NavbarVariant.Home} />
        <Outlet />
      </Stack>
    )
  }
}
