import React from 'react'
import './Login.scss'
import { toast } from 'react-toastify'
import { loginUser, ILoginCredentials } from 'services/userService'
import { Form, Button } from 'react-bootstrap'
import { Link, Navigate } from 'react-router-dom'
import NetworkError from '../errors/networkError'
import { Check2 } from 'react-bootstrap-icons'

type State = {
  redirect: string | undefined
  mail: string
  errorMail: string | undefined
  password: string
  errorPassword: string | undefined
}

export default class Login extends React.Component<unknown, State> {
  state: State = {
    redirect: undefined,
    mail: '',
    errorMail: undefined,
    password: '',
    errorPassword: undefined,
  }

  validateForm = (): boolean => {
    let newErrorMail = undefined
    let newErrorPassword = undefined

    if (!this.state.mail) {
      newErrorMail = 'Mailadresse wird benötigt'
    } else if (!/\S+@\S+\.\S+/.test(this.state.mail)) {
      newErrorMail = 'Mailadresse ist ungültig'
    }
    if (!this.state.password) {
      newErrorPassword = 'Passwort wird benötigt'
    } else if (this.state.password.length < 6) {
      newErrorPassword = 'Passwort muss mindestens 6 Zeichen lang sein'
    }

    this.setState({ errorMail: newErrorMail, errorPassword: newErrorPassword })

    return (newErrorMail || newErrorPassword) !== undefined
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (this.validateForm()) {
      return
    }

    const credentials: ILoginCredentials = {
      email: this.state.mail,
      password: this.state.password,
    }

    toast
      .promise(loginUser(credentials), {
        pending: 'Login ⌛',
        success: 'Login erfolgreich',
        error: {
          render({ data }) {
            if (data instanceof NetworkError) {
              return `${(data as NetworkError).getMessage()}`
            }
            // TODO: should not happen
            return `Login fehlgeschlagen: ${data}`
          },
        },
      })
      .then(() => {
        this.setState({ redirect: '/project' })
      })
      .catch((e) => {
        console.log('Unbekannter Login fehler', e.message)
      })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className='login-wrapper'>
        <div className='login-form-container'>
          <h1 className='login-title'>Bitte einloggen:</h1>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Mailadresse</Form.Label>
              <Form.Control
                type='email'
                placeholder='Mailadresse eingeben'
                value={this.state.mail}
                required
                onChange={(e) => this.setState({ mail: e.target.value })}
                isInvalid={!!this.state.errorMail}
              />
              <Form.Control.Feedback type='invalid'>{this.state.errorMail}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                type='password'
                placeholder='Passwort eingeben'
                required
                onChange={(e) => this.setState({ password: e.target.value })}
                isInvalid={!!this.state.errorPassword}
              />
              <Form.Control.Feedback type='invalid'>{this.state.errorPassword}</Form.Control.Feedback>
            </Form.Group>
            <Button variant='primary' type='submit' className='login-button'>
              Login
              <Check2 />
            </Button>
          </Form>
          <p>
            <br />
          </p>
          <div>
            <Link to='/register'>
              <Button variant='link'>Neuer Kunde? Bitte registrieren</Button>
            </Link>
          </div>
          <p>
            <br />
          </p>
        </div>
      </div>
    )
  }
}
