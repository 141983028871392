import { setToken as setSessionToken, getToken as getSesstionToken, clearSession } from './sessionService'
import { clearCache } from './cacheService'

export function isUserLoggedIn(): boolean {
  return getToken() !== undefined
}

export function setToken(userToken: object /* IUserSession*/): void {
  setSessionToken(JSON.stringify(userToken))
}

export function getToken(): string | undefined {
  const tokenString = getSesstionToken()

  if (tokenString == null) {
    return undefined
  }

  return tokenString
}

export function clearTokenAndAllSessions(): void {
  clearSession()
  clearCache()
}
