import * as React from 'react'
import { Container } from 'react-bootstrap'
import { Navigate, Outlet } from 'react-router'
import { isUserLoggedIn } from 'services/authService'

export default class LogionLayout extends React.Component {
  render() {
    if (isUserLoggedIn()) {
      return <Navigate to='/' />
    }
    return (
      <Container className='layoutContainer'>
        <Outlet />
      </Container>
    )
  }
}
