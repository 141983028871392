import { AccountApi, AccountApiAccountRegisterRequest, AccountApiAccountGetRequest } from '../API/gen'
import { setAccount, setUser, getAccount, getUser } from './sessionService'
import { IAccount } from 'interfaces/account'
import { ICompany } from 'interfaces/company'
import { IAddress } from 'interfaces/address'
import { IUser } from 'interfaces/user'

export const accountApi = new AccountApi(undefined, process.env.REACT_APP_SERVER_URL)

export async function createAccount(
  newAccount: IAccount,
  newUser: IUser,
  newCompany: ICompany | null,
  newAddress: IAddress,
): Promise<IAccount> {
  const request: AccountApiAccountRegisterRequest = {
    registerData: {
      account: newAccount,
      user: newUser,
      address: newAddress,
    },
  }

  if (newCompany != null) {
    request.registerData.company = newCompany
  }
  return accountApi.accountRegister(request).then((response) => {
    if (response.status === 202) {
      setAccount(response.data)
      //Requires confirm of email...
      // set user??
      return response.data
    }
    // TODO: Test error
    console.log('Register errro', response.data, response.statusText)
    throw Error(response.statusText)
  })
}

export async function loadAccount(): Promise<IAccount> {
  const account = getAccount()
  if (account !== null) {
    return account
  }

  const user = getUser()

  if (user === null || user.accountId === null) {
    throw Error('User not logged in')
  }
  const request: AccountApiAccountGetRequest = {
    accountId: user.accountId?.toLowerCase() ?? '',
  }

  return accountApi.accountGet(request).then((response) => {
    if (response.status === 200) {
      setAccount(response.data)
      return response.data
    }
    // TODO: Test error
    console.log('Register errro', response.data, response.statusText)
    throw Error(response.statusText)
  })
}
