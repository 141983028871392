import { UserApi, UserApiUserLoginRequest, UserLoginRequest, UserApiUserPostRequest } from '../API/gen'
import NetworkError from '../errors/networkError'
import { setToken, clearTokenAndAllSessions } from './authService'
import { IUser } from '../interfaces/user'
import { setUser } from './sessionService'
import { loadAccount } from './accountService'

export const userApi = new UserApi(undefined, process.env.REACT_APP_SERVER_URL)

export type ILoginCredentials = UserLoginRequest

export async function loginUser(credentials: ILoginCredentials): Promise<IUser> {
  const request: UserApiUserLoginRequest = {
    userLoginRequest: credentials,
  }
  return userApi
    .userLogin(request)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
      // TODO: Test error
      console.log('Register errro', response.data, response.statusText)
      throw Error(response.statusText)
    })
    .then((data) => {
      setToken(data)
      setUser(data)
      loadAccount()
      return data
    })
    .catch((e) => {
      throw new NetworkError(e)
    })
}

export async function logoutUser(): Promise<void> {
  clearTokenAndAllSessions()
  return userApi
    .userLogout()
    .then((response) => {
      if (response.status === 204) {
        return
      }
      // TODO: Test error
      console.log('Register errro', response.data, response.statusText)
      throw Error(response.statusText)
    })
    .catch((e) => {
      throw new NetworkError(e)
    })
}

export async function createUser(user: IUser): Promise<IUser> {
  const request: UserApiUserPostRequest = {
    user: user,
  }
  return userApi
    .userPost(request)
    .then((response) => {
      if (response.status === 202) {
        return response.data
      }
      // TODO: Test error
      console.log('Register error', response.data, response.statusText)
      throw Error(response.statusText)
    })
    .catch((e) => {
      throw new NetworkError(e)
    })
}

export async function loadUser(): Promise<void> {
  // TODO: Implement
  return logoutUser()
}
