import { AxiosError } from 'axios'

export default class NetworkError extends Error {
  error: AxiosError

  constructor(error: AxiosError) {
    super(error.message)
    this.name = 'NetworkError'
    this.error = error

    // Set the prototype explicitly to maintain the correct prototype chain
    Object.setPrototypeOf(this, NetworkError.prototype)
  }

  getMessage(): string {
    switch (this.error.code) {
      case AxiosError.ERR_FR_TOO_MANY_REDIRECTS:
        return 'Too many redirects.'
      case AxiosError.ERR_BAD_OPTION_VALUE:
        return 'The option value provided was invalid.'
      case AxiosError.ERR_BAD_OPTION:
        return 'The option provided was invalid.'
      case AxiosError.ERR_NETWORK:
        return 'A network error occurred.'
      case AxiosError.ERR_DEPRECATED:
        return 'The feature has been deprecated.'
      case AxiosError.ERR_BAD_RESPONSE:
        return 'The response was invalid.'
      case AxiosError.ERR_BAD_REQUEST:
        return 'The request was invalid.'
      case AxiosError.ERR_NOT_SUPPORT:
        return 'The feature is not supported.'
      case AxiosError.ERR_INVALID_URL:
        return 'The URL provided was invalid.'
      case AxiosError.ERR_CANCELED:
        return 'The request was canceled.'
      case AxiosError.ECONNABORTED:
        return 'The connection has been aborted.'
      case AxiosError.ETIMEDOUT:
        return 'The request took too long to process.'
      default:
        return 'An unknown error occurred.'
    }
  }
}
