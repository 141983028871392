import React from 'react'
import { Link } from 'react-router-dom'
import { logoutUser } from 'services/userService'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router'
import {
  Palette as CatIcon,
  Person as UserIcon,
  Building as CompanyIcon,
  Speedometer2 as BoardIcon,
  House as ProjectIcon,
} from 'react-bootstrap-icons'
import './SideNavbar.scss'

// import {Seidebar, SeidebarMenu} from 'react-bootstrap-sidebar-menu'
import Sidebar from 'react-bootstrap-sidebar-menu'
import logo from '../images/logo192.png'

type State = {
  redirect: string | undefined
  isVisible: boolean
}

export default class SideNavbar extends React.Component<unknown, State> {
  state: State = {
    redirect: undefined,
    isVisible: true,
  }

  logOut = () => {
    console.log('logout')
    toast
      .promise(logoutUser(), {
        pending: 'Logout ⌛',
        success: 'Logout erfolgreich',
        error: 'Logout error!',
      })
      .then(() => {
        this.setState({ redirect: '/' })
      })
      .catch((e: Error) => {
        console.log('Logout error', e.message)
      })
  }

  logIn = () => {
    console.log('login')
    this.setState({ redirect: '/login' })
  }

  register = () => {
    console.log('register')
    this.setState({ redirect: '/register' })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    const theme = 'light'
    return (
      <Sidebar variant={theme} bg={theme} expand='sm'>
        <Sidebar.Collapse>
          <Sidebar.Header>
            <Sidebar.Brand>{/* <img src={logo} className='logoImgMenu'></img>*/}</Sidebar.Brand>
            <Sidebar.Toggle />
          </Sidebar.Header>
          <Sidebar.Body>
            <Sidebar.Nav>
              <Sidebar.Nav.Link hidden as={Link} to='/dashboard'>
                <Sidebar.Nav.Icon>
                  <BoardIcon />
                </Sidebar.Nav.Icon>
                <Sidebar.Nav.Title>Dashboard</Sidebar.Nav.Title>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link as={Link} to='/project'>
                <Sidebar.Nav.Icon>
                  <ProjectIcon />
                </Sidebar.Nav.Icon>
                <Sidebar.Nav.Title>Projekte</Sidebar.Nav.Title>
              </Sidebar.Nav.Link>
              <Sidebar.Sub>
                <Sidebar.Sub.Toggle>
                  <Sidebar.Nav.Icon />
                  <Sidebar.Nav.Title>Konfig</Sidebar.Nav.Title>
                </Sidebar.Sub.Toggle>
                <Sidebar.Sub.Collapse>
                  <Sidebar.Nav>
                    <Sidebar.Nav.Link as={Link} to='/category'>
                      <Sidebar.Nav.Icon>
                        <CatIcon />
                      </Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Material Kategorien</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>
                  </Sidebar.Nav>
                  <Sidebar.Nav hidden>
                    <Sidebar.Nav.Link as={Link} to='/user'>
                      <Sidebar.Nav.Icon>
                        <UserIcon />
                      </Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Anwender</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>
                  </Sidebar.Nav>
                  <Sidebar.Nav>
                    <Sidebar.Nav.Link as={Link} to='/company'>
                      <Sidebar.Nav.Icon>
                        <CompanyIcon />
                      </Sidebar.Nav.Icon>
                      <Sidebar.Nav.Title>Firma</Sidebar.Nav.Title>
                    </Sidebar.Nav.Link>
                  </Sidebar.Nav>
                </Sidebar.Sub.Collapse>
              </Sidebar.Sub>
            </Sidebar.Nav>
          </Sidebar.Body>
        </Sidebar.Collapse>
      </Sidebar>
    )
  }
}
