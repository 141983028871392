/* tslint:disable */
/* eslint-disable */
/**
 * Colorpass API
 * This is hte backend for the ColorPass page.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base'

/**
 * An account of the ColorPass system
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {string}
   * @memberof Account
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Account
   */
  addressId?: string
  /**
   *
   * @type {string}
   * @memberof Account
   */
  companyId?: string
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street: string
  /**
   *
   * @type {number}
   * @memberof Address
   */
  postalCode: number
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country: string
}
/**
 *
 * @export
 * @interface Billing
 */
export interface Billing {
  /**
   *
   * @type {string}
   * @memberof Billing
   */
  id?: string
}
/**
 * A company of the ColorPass system
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {string}
   * @memberof Company
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Company
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Company
   */
  vat?: string
  /**
   *
   * @type {string}
   * @memberof Company
   */
  email: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const ItemState = {
  /**
   * A new item which is under work
   */
  OPEN: '0',
  /**
   * Item is ready for review
   */
  REVIEW: '1',
  /**
   * Item has been reviewed and is published
   */
  DONE: '2',
  /**
   * Item has been deleted
   */
  DELETED: '3',
} as const

export type ItemState = (typeof ItemState)[keyof typeof ItemState]

/**
 *
 * @export
 * @interface MaterialCategory
 */
export interface MaterialCategory {
  /**
   *
   * @type {string}
   * @memberof MaterialCategory
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof MaterialCategory
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof MaterialCategory
   */
  name: string
  /**
   *
   * @type {ItemState}
   * @memberof MaterialCategory
   */
  state?: ItemState
  /**
   *
   * @type {string}
   * @memberof MaterialCategory
   */
  note?: string
  /**
   *
   * @type {string}
   * @memberof MaterialCategory
   */
  text?: string
  /**
   *
   * @type {WarningLevel}
   * @memberof MaterialCategory
   */
  level?: WarningLevel
}

/**
 *
 * @export
 * @interface Position
 */
export interface Position {
  /**
   *
   * @type {string}
   * @memberof Position
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Position
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof Position
   */
  name: string
  /**
   *
   * @type {ItemState}
   * @memberof Position
   */
  state?: ItemState
  /**
   *
   * @type {string}
   * @memberof Position
   */
  material?: string
  /**
   *
   * @type {string}
   * @memberof Position
   */
  color?: string
  /**
   *
   * @type {MaterialCategory}
   * @memberof Position
   */
  category?: MaterialCategory
}

/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  /**
   * A URI reference that identifies the problem type
   * @type {string}
   * @memberof ProblemDetails
   */
  type?: string
  /**
   * A short summary of the problem type
   * @type {string}
   * @memberof ProblemDetails
   */
  title?: string
  /**
   * An explanation specific to this occurrence of the problem
   * @type {string}
   * @memberof ProblemDetails
   */
  detail?: string
  /**
   * The status code for this occurrence of the problem
   * @type {number}
   * @memberof ProblemDetails
   */
  status?: number
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {string}
   * @memberof Project
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Project
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name: string
  /**
   *
   * @type {ItemState}
   * @memberof Project
   */
  state?: ItemState
  /**
   *
   * @type {Array<string>}
   * @memberof Project
   */
  userIds?: Array<string>
}

/**
 * Reginster an account with user and company
 * @export
 * @interface RegisterData
 */
export interface RegisterData {
  /**
   *
   * @type {Account}
   * @memberof RegisterData
   */
  account: Account
  /**
   *
   * @type {User}
   * @memberof RegisterData
   */
  user: User
  /**
   *
   * @type {Company}
   * @memberof RegisterData
   */
  company?: Company
  /**
   *
   * @type {Address}
   * @memberof RegisterData
   */
  address: Address
}
/**
 * A user of the ColorPass system
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  password: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  role?: UserRoleEnum
  /**
   *
   * @type {string}
   * @memberof User
   */
  accountId?: string
}

export const UserRoleEnum = {
  Admin: 'admin',
  User: 'user',
  Reviewer: 'reviewer',
} as const

export type UserRoleEnum = (typeof UserRoleEnum)[keyof typeof UserRoleEnum]

/**
 *
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
  /**
   *
   * @type {string}
   * @memberof UserLoginRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UserLoginRequest
   */
  password: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const WarningLevel = {
  /**
   * Uses for infomation. Notice signs are typically used for activities that result in property damage but no personal injury.
   */
  NOTICE: '0',
  /**
   * Use Caution for information on Overhead / Clearance hazards. Do not use if there is a chance of death or serious injury or illness.
   */
  CAUTION: '1',
  /**
   * Use Warning Signs for material which is toxic. Limit Warning to personal injury.
   */
  WARNING: '2',
  /**
   * Use for Danger Signs for very toxic and dangerous materials. Limit Danger to potentially lethal environments.
   */
  DANGER: '3',
} as const

export type WarningLevel = (typeof WarningLevel)[keyof typeof WarningLevel]

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get an account by id
     * @param {string} accountId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountGet: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('accountGet', 'accountId', accountId)
      const localVarPath = `/account/{accountId}`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new account with User and Company
     * @param {RegisterData} registerData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountRegister: async (registerData: RegisterData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'registerData' is not null or undefined
      assertParamExists('accountRegister', 'registerData', registerData)
      const localVarPath = `/account/register`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(registerData, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get an account by id
     * @param {string} accountId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountGet(
      accountId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountGet(accountId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['AccountApi.accountGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a new account with User and Company
     * @param {RegisterData} registerData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountRegister(
      registerData: RegisterData,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountRegister(registerData, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['AccountApi.accountRegister']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountApiFp(configuration)
  return {
    /**
     *
     * @summary Get an account by id
     * @param {AccountApiAccountGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountGet(requestParameters: AccountApiAccountGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
      return localVarFp.accountGet(requestParameters.accountId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new account with User and Company
     * @param {AccountApiAccountRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountRegister(requestParameters: AccountApiAccountRegisterRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
      return localVarFp.accountRegister(requestParameters.registerData, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for accountGet operation in AccountApi.
 * @export
 * @interface AccountApiAccountGetRequest
 */
export interface AccountApiAccountGetRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof AccountApiAccountGet
   */
  readonly accountId: string
}

/**
 * Request parameters for accountRegister operation in AccountApi.
 * @export
 * @interface AccountApiAccountRegisterRequest
 */
export interface AccountApiAccountRegisterRequest {
  /**
   *
   * @type {RegisterData}
   * @memberof AccountApiAccountRegister
   */
  readonly registerData: RegisterData
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   *
   * @summary Get an account by id
   * @param {AccountApiAccountGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountGet(requestParameters: AccountApiAccountGetRequest, options?: RawAxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .accountGet(requestParameters.accountId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new account with User and Company
   * @param {AccountApiAccountRegisterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public accountRegister(requestParameters: AccountApiAccountRegisterRequest, options?: RawAxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .accountRegister(requestParameters.registerData, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a address by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} addressId Unique identifier of the address to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGet: async (accountId: string, addressId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('addressGet', 'accountId', accountId)
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('addressGet', 'addressId', addressId)
      const localVarPath = `/account/{accountId}/address/{addressId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'addressId'}}`, encodeURIComponent(String(addressId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new address
     * @param {string} accountId Unique identifier of the account
     * @param {Address} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressPost: async (accountId: string, address: Address, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('addressPost', 'accountId', accountId)
      // verify required parameter 'address' is not null or undefined
      assertParamExists('addressPost', 'address', address)
      const localVarPath = `/account/{accountId}/address`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update a address
     * @param {string} accountId Unique identifier of the account
     * @param {string} addressId Unique identifier of the address to get
     * @param {Address} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressPut: async (
      accountId: string,
      addressId: string,
      address: Address,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('addressPut', 'accountId', accountId)
      // verify required parameter 'addressId' is not null or undefined
      assertParamExists('addressPut', 'addressId', addressId)
      // verify required parameter 'address' is not null or undefined
      assertParamExists('addressPut', 'address', address)
      const localVarPath = `/account/{accountId}/address/{addressId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'addressId'}}`, encodeURIComponent(String(addressId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get a address by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} addressId Unique identifier of the address to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressGet(
      accountId: string,
      addressId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressGet(accountId, addressId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['AddressApi.addressGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a new address
     * @param {string} accountId Unique identifier of the account
     * @param {Address} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressPost(
      accountId: string,
      address: Address,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressPost(accountId, address, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['AddressApi.addressPost']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update a address
     * @param {string} accountId Unique identifier of the account
     * @param {string} addressId Unique identifier of the address to get
     * @param {Address} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressPut(
      accountId: string,
      addressId: string,
      address: Address,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressPut(accountId, addressId, address, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['AddressApi.addressPut']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AddressApiFp(configuration)
  return {
    /**
     *
     * @summary Get a address by id
     * @param {AddressApiAddressGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGet(requestParameters: AddressApiAddressGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Address> {
      return localVarFp
        .addressGet(requestParameters.accountId, requestParameters.addressId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new address
     * @param {AddressApiAddressPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressPost(requestParameters: AddressApiAddressPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Address> {
      return localVarFp
        .addressPost(requestParameters.accountId, requestParameters.address, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update a address
     * @param {AddressApiAddressPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressPut(requestParameters: AddressApiAddressPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .addressPut(requestParameters.accountId, requestParameters.addressId, requestParameters.address, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for addressGet operation in AddressApi.
 * @export
 * @interface AddressApiAddressGetRequest
 */
export interface AddressApiAddressGetRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof AddressApiAddressGet
   */
  readonly accountId: string

  /**
   * Unique identifier of the address to get
   * @type {string}
   * @memberof AddressApiAddressGet
   */
  readonly addressId: string
}

/**
 * Request parameters for addressPost operation in AddressApi.
 * @export
 * @interface AddressApiAddressPostRequest
 */
export interface AddressApiAddressPostRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof AddressApiAddressPost
   */
  readonly accountId: string

  /**
   *
   * @type {Address}
   * @memberof AddressApiAddressPost
   */
  readonly address: Address
}

/**
 * Request parameters for addressPut operation in AddressApi.
 * @export
 * @interface AddressApiAddressPutRequest
 */
export interface AddressApiAddressPutRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof AddressApiAddressPut
   */
  readonly accountId: string

  /**
   * Unique identifier of the address to get
   * @type {string}
   * @memberof AddressApiAddressPut
   */
  readonly addressId: string

  /**
   *
   * @type {Address}
   * @memberof AddressApiAddressPut
   */
  readonly address: Address
}

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
  /**
   *
   * @summary Get a address by id
   * @param {AddressApiAddressGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressGet(requestParameters: AddressApiAddressGetRequest, options?: RawAxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressGet(requestParameters.accountId, requestParameters.addressId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new address
   * @param {AddressApiAddressPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressPost(requestParameters: AddressApiAddressPostRequest, options?: RawAxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressPost(requestParameters.accountId, requestParameters.address, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a address
   * @param {AddressApiAddressPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressPut(requestParameters: AddressApiAddressPutRequest, options?: RawAxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressPut(requestParameters.accountId, requestParameters.addressId, requestParameters.address, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all material categories
     * @param {string} accountId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesGet: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('categoriesGet', 'accountId', accountId)
      const localVarPath = `/account/{accountId}/category`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get a category by id
     * @param {string} accountId Unique identifier of the account to get
     * @param {string} categoryId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoryGet: async (accountId: string, categoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('categoryGet', 'accountId', accountId)
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoryGet', 'categoryId', categoryId)
      const localVarPath = `/account/{accountId}/category/{categoryId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'categoryId'}}`, encodeURIComponent(String(categoryId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new material category
     * @param {string} accountId Unique identifier of the account to get
     * @param {MaterialCategory} materialCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoryPost: async (
      accountId: string,
      materialCategory: MaterialCategory,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('categoryPost', 'accountId', accountId)
      // verify required parameter 'materialCategory' is not null or undefined
      assertParamExists('categoryPost', 'materialCategory', materialCategory)
      const localVarPath = `/account/{accountId}/category`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(materialCategory, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update a category
     * @param {string} accountId Unique identifier of the account to get
     * @param {string} categoryId Unique identifier of the account to get
     * @param {MaterialCategory} materialCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoryPut: async (
      accountId: string,
      categoryId: string,
      materialCategory: MaterialCategory,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('categoryPut', 'accountId', accountId)
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoryPut', 'categoryId', categoryId)
      // verify required parameter 'materialCategory' is not null or undefined
      assertParamExists('categoryPut', 'materialCategory', materialCategory)
      const localVarPath = `/account/{accountId}/category/{categoryId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'categoryId'}}`, encodeURIComponent(String(categoryId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(materialCategory, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get all material categories
     * @param {string} accountId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesGet(
      accountId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialCategory>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(accountId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CategoryApi.categoriesGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get a category by id
     * @param {string} accountId Unique identifier of the account to get
     * @param {string} categoryId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoryGet(
      accountId: string,
      categoryId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoryGet(accountId, categoryId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CategoryApi.categoryGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a new material category
     * @param {string} accountId Unique identifier of the account to get
     * @param {MaterialCategory} materialCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoryPost(
      accountId: string,
      materialCategory: MaterialCategory,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPost(accountId, materialCategory, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CategoryApi.categoryPost']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update a category
     * @param {string} accountId Unique identifier of the account to get
     * @param {string} categoryId Unique identifier of the account to get
     * @param {MaterialCategory} materialCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoryPut(
      accountId: string,
      categoryId: string,
      materialCategory: MaterialCategory,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPut(accountId, categoryId, materialCategory, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CategoryApi.categoryPut']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoryApiFp(configuration)
  return {
    /**
     *
     * @summary Get all material categories
     * @param {CategoryApiCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesGet(
      requestParameters: CategoryApiCategoriesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<MaterialCategory>> {
      return localVarFp.categoriesGet(requestParameters.accountId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get a category by id
     * @param {CategoryApiCategoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoryGet(requestParameters: CategoryApiCategoryGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MaterialCategory> {
      return localVarFp
        .categoryGet(requestParameters.accountId, requestParameters.categoryId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new material category
     * @param {CategoryApiCategoryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoryPost(requestParameters: CategoryApiCategoryPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MaterialCategory> {
      return localVarFp
        .categoryPost(requestParameters.accountId, requestParameters.materialCategory, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update a category
     * @param {CategoryApiCategoryPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoryPut(requestParameters: CategoryApiCategoryPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .categoryPut(requestParameters.accountId, requestParameters.categoryId, requestParameters.materialCategory, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for categoriesGet operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoriesGetRequest
 */
export interface CategoryApiCategoriesGetRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof CategoryApiCategoriesGet
   */
  readonly accountId: string
}

/**
 * Request parameters for categoryGet operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryGetRequest
 */
export interface CategoryApiCategoryGetRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof CategoryApiCategoryGet
   */
  readonly accountId: string

  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof CategoryApiCategoryGet
   */
  readonly categoryId: string
}

/**
 * Request parameters for categoryPost operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryPostRequest
 */
export interface CategoryApiCategoryPostRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof CategoryApiCategoryPost
   */
  readonly accountId: string

  /**
   *
   * @type {MaterialCategory}
   * @memberof CategoryApiCategoryPost
   */
  readonly materialCategory: MaterialCategory
}

/**
 * Request parameters for categoryPut operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryPutRequest
 */
export interface CategoryApiCategoryPutRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof CategoryApiCategoryPut
   */
  readonly accountId: string

  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof CategoryApiCategoryPut
   */
  readonly categoryId: string

  /**
   *
   * @type {MaterialCategory}
   * @memberof CategoryApiCategoryPut
   */
  readonly materialCategory: MaterialCategory
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
  /**
   *
   * @summary Get all material categories
   * @param {CategoryApiCategoriesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoriesGet(requestParameters: CategoryApiCategoriesGetRequest, options?: RawAxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoriesGet(requestParameters.accountId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get a category by id
   * @param {CategoryApiCategoryGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoryGet(requestParameters: CategoryApiCategoryGetRequest, options?: RawAxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoryGet(requestParameters.accountId, requestParameters.categoryId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new material category
   * @param {CategoryApiCategoryPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoryPost(requestParameters: CategoryApiCategoryPostRequest, options?: RawAxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoryPost(requestParameters.accountId, requestParameters.materialCategory, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a category
   * @param {CategoryApiCategoryPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public categoryPut(requestParameters: CategoryApiCategoryPutRequest, options?: RawAxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .categoryPut(requestParameters.accountId, requestParameters.categoryId, requestParameters.materialCategory, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a company by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} companyId Unique identifier of the company to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyGet: async (accountId: string, companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('companyGet', 'accountId', accountId)
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyGet', 'companyId', companyId)
      const localVarPath = `/account/{accountId}/company/{companyId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new company
     * @param {string} accountId Unique identifier of the account
     * @param {Company} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyPost: async (accountId: string, company: Company, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('companyPost', 'accountId', accountId)
      // verify required parameter 'company' is not null or undefined
      assertParamExists('companyPost', 'company', company)
      const localVarPath = `/account/{accountId}/company`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update a company
     * @param {string} accountId Unique identifier of the account
     * @param {string} companyId Unique identifier of the company to get
     * @param {Company} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyPut: async (
      accountId: string,
      companyId: string,
      company: Company,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('companyPut', 'accountId', accountId)
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('companyPut', 'companyId', companyId)
      // verify required parameter 'company' is not null or undefined
      assertParamExists('companyPut', 'company', company)
      const localVarPath = `/account/{accountId}/company/{companyId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'companyId'}}`, encodeURIComponent(String(companyId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get a company by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} companyId Unique identifier of the company to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyGet(
      accountId: string,
      companyId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyGet(accountId, companyId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a new company
     * @param {string} accountId Unique identifier of the account
     * @param {Company} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyPost(
      accountId: string,
      company: Company,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyPost(accountId, company, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyPost']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update a company
     * @param {string} accountId Unique identifier of the account
     * @param {string} companyId Unique identifier of the company to get
     * @param {Company} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyPut(
      accountId: string,
      companyId: string,
      company: Company,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyPut(accountId, companyId, company, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyPut']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompanyApiFp(configuration)
  return {
    /**
     *
     * @summary Get a company by id
     * @param {CompanyApiCompanyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyGet(requestParameters: CompanyApiCompanyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
      return localVarFp
        .companyGet(requestParameters.accountId, requestParameters.companyId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new company
     * @param {CompanyApiCompanyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyPost(requestParameters: CompanyApiCompanyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
      return localVarFp
        .companyPost(requestParameters.accountId, requestParameters.company, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update a company
     * @param {CompanyApiCompanyPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyPut(requestParameters: CompanyApiCompanyPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .companyPut(requestParameters.accountId, requestParameters.companyId, requestParameters.company, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for companyGet operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyGetRequest
 */
export interface CompanyApiCompanyGetRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof CompanyApiCompanyGet
   */
  readonly accountId: string

  /**
   * Unique identifier of the company to get
   * @type {string}
   * @memberof CompanyApiCompanyGet
   */
  readonly companyId: string
}

/**
 * Request parameters for companyPost operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyPostRequest
 */
export interface CompanyApiCompanyPostRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof CompanyApiCompanyPost
   */
  readonly accountId: string

  /**
   *
   * @type {Company}
   * @memberof CompanyApiCompanyPost
   */
  readonly company: Company
}

/**
 * Request parameters for companyPut operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyPutRequest
 */
export interface CompanyApiCompanyPutRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof CompanyApiCompanyPut
   */
  readonly accountId: string

  /**
   * Unique identifier of the company to get
   * @type {string}
   * @memberof CompanyApiCompanyPut
   */
  readonly companyId: string

  /**
   *
   * @type {Company}
   * @memberof CompanyApiCompanyPut
   */
  readonly company: Company
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
  /**
   *
   * @summary Get a company by id
   * @param {CompanyApiCompanyGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companyGet(requestParameters: CompanyApiCompanyGetRequest, options?: RawAxiosRequestConfig) {
    return CompanyApiFp(this.configuration)
      .companyGet(requestParameters.accountId, requestParameters.companyId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new company
   * @param {CompanyApiCompanyPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companyPost(requestParameters: CompanyApiCompanyPostRequest, options?: RawAxiosRequestConfig) {
    return CompanyApiFp(this.configuration)
      .companyPost(requestParameters.accountId, requestParameters.company, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a company
   * @param {CompanyApiCompanyPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companyPut(requestParameters: CompanyApiCompanyPutRequest, options?: RawAxiosRequestConfig) {
    return CompanyApiFp(this.configuration)
      .companyPut(requestParameters.accountId, requestParameters.companyId, requestParameters.company, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get a project position by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the project
     * @param {string} positionId Unique identifier of the position to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionGet: async (
      accountId: string,
      projectId: string,
      positionId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('positionGet', 'accountId', accountId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('positionGet', 'projectId', projectId)
      // verify required parameter 'positionId' is not null or undefined
      assertParamExists('positionGet', 'positionId', positionId)
      const localVarPath = `/account/{accountId}/project/{projectId}/position/{positionId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'positionId'}}`, encodeURIComponent(String(positionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new Position in a project
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the project
     * @param {Position} position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionPost: async (
      accountId: string,
      projectId: string,
      position: Position,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('positionPost', 'accountId', accountId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('positionPost', 'projectId', projectId)
      // verify required parameter 'position' is not null or undefined
      assertParamExists('positionPost', 'position', position)
      const localVarPath = `/account/{accountId}/project/{projectId}/position`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(position, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update a position
     * @param {string} accountId Unique identifier of the account to get
     * @param {string} projectId Unique identifier of the account to get
     * @param {string} positionId Unique identifier of the position to get
     * @param {Position} position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionPut: async (
      accountId: string,
      projectId: string,
      positionId: string,
      position: Position,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('positionPut', 'accountId', accountId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('positionPut', 'projectId', projectId)
      // verify required parameter 'positionId' is not null or undefined
      assertParamExists('positionPut', 'positionId', positionId)
      // verify required parameter 'position' is not null or undefined
      assertParamExists('positionPut', 'position', position)
      const localVarPath = `/account/{accountId}/project/{projectId}/position/{positionId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'positionId'}}`, encodeURIComponent(String(positionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(position, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get all postions
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionsGet: async (accountId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('positionsGet', 'accountId', accountId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('positionsGet', 'projectId', projectId)
      const localVarPath = `/account/{accountId}/project/{projectId}/position`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get a project by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectGet: async (accountId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('projectGet', 'accountId', accountId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('projectGet', 'projectId', projectId)
      const localVarPath = `/account/{accountId}/project/{projectId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new Project
     * @param {string} accountId Unique identifier of the account
     * @param {Project} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectPost: async (accountId: string, project: Project, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('projectPost', 'accountId', accountId)
      // verify required parameter 'project' is not null or undefined
      assertParamExists('projectPost', 'project', project)
      const localVarPath = `/account/{accountId}/project`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update a project
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the projec
     * @param {Project} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectPut: async (
      accountId: string,
      projectId: string,
      project: Project,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('projectPut', 'accountId', accountId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('projectPut', 'projectId', projectId)
      // verify required parameter 'project' is not null or undefined
      assertParamExists('projectPut', 'project', project)
      const localVarPath = `/account/{accountId}/project/{projectId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get all project
     * @param {string} accountId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGet: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('projectsGet', 'accountId', accountId)
      const localVarPath = `/account/{accountId}/project`.replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get a project position by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the project
     * @param {string} positionId Unique identifier of the position to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async positionGet(
      accountId: string,
      projectId: string,
      positionId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Position>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.positionGet(accountId, projectId, positionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.positionGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a new Position in a project
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the project
     * @param {Position} position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async positionPost(
      accountId: string,
      projectId: string,
      position: Position,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Position>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.positionPost(accountId, projectId, position, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.positionPost']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update a position
     * @param {string} accountId Unique identifier of the account to get
     * @param {string} projectId Unique identifier of the account to get
     * @param {string} positionId Unique identifier of the position to get
     * @param {Position} position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async positionPut(
      accountId: string,
      projectId: string,
      positionId: string,
      position: Position,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.positionPut(accountId, projectId, positionId, position, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.positionPut']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get all postions
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async positionsGet(
      accountId: string,
      projectId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Position>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.positionsGet(accountId, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.positionsGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get a project by id
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectGet(
      accountId: string,
      projectId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectGet(accountId, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a new Project
     * @param {string} accountId Unique identifier of the account
     * @param {Project} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectPost(
      accountId: string,
      project: Project,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectPost(accountId, project, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectPost']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update a project
     * @param {string} accountId Unique identifier of the account
     * @param {string} projectId Unique identifier of the projec
     * @param {Project} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectPut(
      accountId: string,
      projectId: string,
      project: Project,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectPut(accountId, projectId, project, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectPut']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get all project
     * @param {string} accountId Unique identifier of the account to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async projectsGet(
      accountId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGet(accountId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectsGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProjectApiFp(configuration)
  return {
    /**
     *
     * @summary Get a project position by id
     * @param {ProjectApiPositionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionGet(requestParameters: ProjectApiPositionGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Position> {
      return localVarFp
        .positionGet(requestParameters.accountId, requestParameters.projectId, requestParameters.positionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new Position in a project
     * @param {ProjectApiPositionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionPost(requestParameters: ProjectApiPositionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Position> {
      return localVarFp
        .positionPost(requestParameters.accountId, requestParameters.projectId, requestParameters.position, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update a position
     * @param {ProjectApiPositionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionPut(requestParameters: ProjectApiPositionPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .positionPut(
          requestParameters.accountId,
          requestParameters.projectId,
          requestParameters.positionId,
          requestParameters.position,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get all postions
     * @param {ProjectApiPositionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    positionsGet(requestParameters: ProjectApiPositionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Position>> {
      return localVarFp
        .positionsGet(requestParameters.accountId, requestParameters.projectId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get a project by id
     * @param {ProjectApiProjectGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectGet(requestParameters: ProjectApiProjectGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Project> {
      return localVarFp
        .projectGet(requestParameters.accountId, requestParameters.projectId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new Project
     * @param {ProjectApiProjectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectPost(requestParameters: ProjectApiProjectPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Project> {
      return localVarFp
        .projectPost(requestParameters.accountId, requestParameters.project, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update a project
     * @param {ProjectApiProjectPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectPut(requestParameters: ProjectApiProjectPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .projectPut(requestParameters.accountId, requestParameters.projectId, requestParameters.project, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get all project
     * @param {ProjectApiProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsGet(requestParameters: ProjectApiProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Project>> {
      return localVarFp.projectsGet(requestParameters.accountId, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for positionGet operation in ProjectApi.
 * @export
 * @interface ProjectApiPositionGetRequest
 */
export interface ProjectApiPositionGetRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof ProjectApiPositionGet
   */
  readonly accountId: string

  /**
   * Unique identifier of the project
   * @type {string}
   * @memberof ProjectApiPositionGet
   */
  readonly projectId: string

  /**
   * Unique identifier of the position to get
   * @type {string}
   * @memberof ProjectApiPositionGet
   */
  readonly positionId: string
}

/**
 * Request parameters for positionPost operation in ProjectApi.
 * @export
 * @interface ProjectApiPositionPostRequest
 */
export interface ProjectApiPositionPostRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof ProjectApiPositionPost
   */
  readonly accountId: string

  /**
   * Unique identifier of the project
   * @type {string}
   * @memberof ProjectApiPositionPost
   */
  readonly projectId: string

  /**
   *
   * @type {Position}
   * @memberof ProjectApiPositionPost
   */
  readonly position: Position
}

/**
 * Request parameters for positionPut operation in ProjectApi.
 * @export
 * @interface ProjectApiPositionPutRequest
 */
export interface ProjectApiPositionPutRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof ProjectApiPositionPut
   */
  readonly accountId: string

  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof ProjectApiPositionPut
   */
  readonly projectId: string

  /**
   * Unique identifier of the position to get
   * @type {string}
   * @memberof ProjectApiPositionPut
   */
  readonly positionId: string

  /**
   *
   * @type {Position}
   * @memberof ProjectApiPositionPut
   */
  readonly position: Position
}

/**
 * Request parameters for positionsGet operation in ProjectApi.
 * @export
 * @interface ProjectApiPositionsGetRequest
 */
export interface ProjectApiPositionsGetRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof ProjectApiPositionsGet
   */
  readonly accountId: string

  /**
   * Unique identifier of the project
   * @type {string}
   * @memberof ProjectApiPositionsGet
   */
  readonly projectId: string
}

/**
 * Request parameters for projectGet operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectGetRequest
 */
export interface ProjectApiProjectGetRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof ProjectApiProjectGet
   */
  readonly accountId: string

  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof ProjectApiProjectGet
   */
  readonly projectId: string
}

/**
 * Request parameters for projectPost operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectPostRequest
 */
export interface ProjectApiProjectPostRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof ProjectApiProjectPost
   */
  readonly accountId: string

  /**
   *
   * @type {Project}
   * @memberof ProjectApiProjectPost
   */
  readonly project: Project
}

/**
 * Request parameters for projectPut operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectPutRequest
 */
export interface ProjectApiProjectPutRequest {
  /**
   * Unique identifier of the account
   * @type {string}
   * @memberof ProjectApiProjectPut
   */
  readonly accountId: string

  /**
   * Unique identifier of the projec
   * @type {string}
   * @memberof ProjectApiProjectPut
   */
  readonly projectId: string

  /**
   *
   * @type {Project}
   * @memberof ProjectApiProjectPut
   */
  readonly project: Project
}

/**
 * Request parameters for projectsGet operation in ProjectApi.
 * @export
 * @interface ProjectApiProjectsGetRequest
 */
export interface ProjectApiProjectsGetRequest {
  /**
   * Unique identifier of the account to get
   * @type {string}
   * @memberof ProjectApiProjectsGet
   */
  readonly accountId: string
}

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
  /**
   *
   * @summary Get a project position by id
   * @param {ProjectApiPositionGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public positionGet(requestParameters: ProjectApiPositionGetRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .positionGet(requestParameters.accountId, requestParameters.projectId, requestParameters.positionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new Position in a project
   * @param {ProjectApiPositionPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public positionPost(requestParameters: ProjectApiPositionPostRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .positionPost(requestParameters.accountId, requestParameters.projectId, requestParameters.position, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a position
   * @param {ProjectApiPositionPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public positionPut(requestParameters: ProjectApiPositionPutRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .positionPut(
        requestParameters.accountId,
        requestParameters.projectId,
        requestParameters.positionId,
        requestParameters.position,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all postions
   * @param {ProjectApiPositionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public positionsGet(requestParameters: ProjectApiPositionsGetRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .positionsGet(requestParameters.accountId, requestParameters.projectId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get a project by id
   * @param {ProjectApiProjectGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectGet(requestParameters: ProjectApiProjectGetRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .projectGet(requestParameters.accountId, requestParameters.projectId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new Project
   * @param {ProjectApiProjectPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectPost(requestParameters: ProjectApiProjectPostRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .projectPost(requestParameters.accountId, requestParameters.project, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a project
   * @param {ProjectApiProjectPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectPut(requestParameters: ProjectApiProjectPutRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .projectPut(requestParameters.accountId, requestParameters.projectId, requestParameters.project, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all project
   * @param {ProjectApiProjectsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectsGet(requestParameters: ProjectApiProjectsGetRequest, options?: RawAxiosRequestConfig) {
    return ProjectApiFp(this.configuration)
      .projectsGet(requestParameters.accountId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete a user
     * @param {string} userId Unique identifier of the user to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDelete: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userDelete', 'userId', userId)
      const localVarPath = `/user/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get a user by id
     * @param {string} userId Unique identifier of the user to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userGet', 'userId', userId)
      const localVarPath = `/user/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary User login
     * @param {UserLoginRequest} userLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogin: async (userLoginRequest: UserLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userLoginRequest' is not null or undefined
      assertParamExists('userLogin', 'userLoginRequest', userLoginRequest)
      const localVarPath = `/user/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(userLoginRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary User logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/logout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a user
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPost: async (user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('userPost', 'user', user)
      const localVarPath = `/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update a user
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPut: async (user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('userPut', 'user', user)
      const localVarPath = `/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Delete a user
     * @param {string} userId Unique identifier of the user to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userDelete(
      userId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(userId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['UserApi.userDelete']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get a user by id
     * @param {string} userId Unique identifier of the user to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGet(
      userId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(userId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['UserApi.userGet']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary User login
     * @param {UserLoginRequest} userLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLogin(
      userLoginRequest: UserLoginRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userLogin(userLoginRequest, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['UserApi.userLogin']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary User logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLogout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userLogout(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['UserApi.userLogout']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Create a user
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPost(user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userPost(user, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['UserApi.userPost']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Update a user
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPut(user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userPut(user, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['UserApi.userPut']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     *
     * @summary Delete a user
     * @param {UserApiUserDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDelete(requestParameters: UserApiUserDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.userDelete(requestParameters.userId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get a user by id
     * @param {UserApiUserGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGet(requestParameters: UserApiUserGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.userGet(requestParameters.userId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary User login
     * @param {UserApiUserLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogin(requestParameters: UserApiUserLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.userLogin(requestParameters.userLoginRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary User logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogout(options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.userLogout(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a user
     * @param {UserApiUserPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPost(requestParameters: UserApiUserPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.userPost(requestParameters.user, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update a user
     * @param {UserApiUserPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPut(requestParameters: UserApiUserPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
      return localVarFp.userPut(requestParameters.user, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for userDelete operation in UserApi.
 * @export
 * @interface UserApiUserDeleteRequest
 */
export interface UserApiUserDeleteRequest {
  /**
   * Unique identifier of the user to delete
   * @type {string}
   * @memberof UserApiUserDelete
   */
  readonly userId: string
}

/**
 * Request parameters for userGet operation in UserApi.
 * @export
 * @interface UserApiUserGetRequest
 */
export interface UserApiUserGetRequest {
  /**
   * Unique identifier of the user to get
   * @type {string}
   * @memberof UserApiUserGet
   */
  readonly userId: string
}

/**
 * Request parameters for userLogin operation in UserApi.
 * @export
 * @interface UserApiUserLoginRequest
 */
export interface UserApiUserLoginRequest {
  /**
   *
   * @type {UserLoginRequest}
   * @memberof UserApiUserLogin
   */
  readonly userLoginRequest: UserLoginRequest
}

/**
 * Request parameters for userPost operation in UserApi.
 * @export
 * @interface UserApiUserPostRequest
 */
export interface UserApiUserPostRequest {
  /**
   *
   * @type {User}
   * @memberof UserApiUserPost
   */
  readonly user: User
}

/**
 * Request parameters for userPut operation in UserApi.
 * @export
 * @interface UserApiUserPutRequest
 */
export interface UserApiUserPutRequest {
  /**
   *
   * @type {User}
   * @memberof UserApiUserPut
   */
  readonly user: User
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Delete a user
   * @param {UserApiUserDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userDelete(requestParameters: UserApiUserDeleteRequest, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userDelete(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get a user by id
   * @param {UserApiUserGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userGet(requestParameters: UserApiUserGetRequest, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userGet(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary User login
   * @param {UserApiUserLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userLogin(requestParameters: UserApiUserLoginRequest, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userLogin(requestParameters.userLoginRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary User logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userLogout(options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userLogout(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a user
   * @param {UserApiUserPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userPost(requestParameters: UserApiUserPostRequest, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userPost(requestParameters.user, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a user
   * @param {UserApiUserPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userPut(requestParameters: UserApiUserPutRequest, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userPut(requestParameters.user, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
