import React from 'react'
import { Col, Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import './Base.scss'

export default class Home extends React.Component<unknown, unknown> {
  render() {
    return (
      <Container className='baseContainer'>
        <Row>
          <Col>
            <h1>Farbenpass</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Wir helfen Ihnen Ihre Farbgläser zu beschriften. Über einen QR Code bekommt der Kunde, die wichtigsten Informationen über den
              Inhalt im Glas.
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}
