import React from 'react'
import './Header.scss'
import logo from 'images/logo.webp'

export default class Header extends React.Component {
  render() {
    return (
      <header className='mainHeader'>
        <div className='logoContainer'>
          {/* Change it to dom link */}
          <a className='logoImage' href='/' title='Farbenpass'>
            <img src={logo} className='logoImg' />
          </a>
          <h1 className='logoText'>Farbenpass</h1>
        </div>
      </header>
    )
  }
}
